'use client';
import { api, getUtmObject } from '@/helpers';
import { useSearchParams } from 'next/navigation';
import { FC, useEffect } from 'react';

export const UtmWrapper: FC = () => {
  const params = useSearchParams();

  useEffect(() => {
    const obj = getUtmObject(params);

    // reset pa cookies if none
    if (!obj.utm_source) {
      let firstTouch = JSON.parse(localStorage.getItem('firstTouch') || 'null');
      let lastTouch = JSON.parse(localStorage.getItem('lastTouch') || 'null');
      let regObj = JSON.parse(sessionStorage.getItem('regUtm') || 'null');

      const cookiesObj = {
        registration_campaign: regObj?.utm_campaign || '',
        registration_medium: regObj?.utm_medium || '',
        registration_source: regObj?.utm_source || '',
        registration_content: regObj?.utm_content || '',
        registration_term: regObj?.utm_term || '',
        first_touch_campaign: firstTouch?.utm_campaign || '',
        first_touch_medium: firstTouch?.utm_medium || '',
        first_touch_source: firstTouch?.utm_source || '',
        first_touch_content: firstTouch?.utm_content || '',
        first_touch_term: firstTouch?.utm_term || '',
        last_touch_campaign: lastTouch?.utm_campaign || '',
        last_touch_medium: lastTouch?.utm_medium || '',
        last_touch_source: lastTouch?.utm_source || '',
        last_touch_content: lastTouch?.utm_content || '',
        last_touch_term: lastTouch?.utm_term || '',
      };

      api.post('/common/set-cookie', cookiesObj);
      return;
    }

    let firstTouch = JSON.parse(localStorage.getItem('firstTouch') || 'null');
    if (!firstTouch) {
      localStorage.setItem('firstTouch', JSON.stringify(obj));
      firstTouch = obj;
    }
    localStorage.setItem('lastTouch', JSON.stringify(obj));

    // current utm source
    sessionStorage.setItem('subscription', JSON.stringify(obj));
    sessionStorage.setItem('regUtm', JSON.stringify(obj));

    const cookiesObj = {
      registration_campaign: obj.utm_campaign,
      registration_medium: obj.utm_medium,
      registration_source: obj.utm_source,
      registration_content: obj.utm_content,
      registration_term: obj.utm_term,
      first_touch_campaign: firstTouch.utm_campaign,
      first_touch_medium: firstTouch.utm_medium,
      first_touch_source: firstTouch.utm_source,
      first_touch_content: firstTouch.utm_content,
      first_touch_term: firstTouch.utm_term,
      last_touch_campaign: obj.utm_campaign,
      last_touch_medium: obj.utm_medium,
      last_touch_source: obj.utm_source,
      last_touch_content: obj.utm_content,
      last_touch_term: obj.utm_term,
    };

    api.post('/common/set-cookie', cookiesObj);
  }, [params]);

  return null;
};
